// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare var require: any

export const environment = {
    production: false,
    appVersion: require('../../package.json').version,
    domodId: 'c825ac17-a806-49f6-9380-9c4385a530bb',
    exclusiveId: 'a2cd9446-93b4-4547-8fcc-cb3c08774b03',
    client: {
        domod: 'domodclient',
        wsh: 'wshclient',
        exc: 'excclient'
    },
    secret: {
        domod: 's3cr3t',
        wsh: 's3cr3t',
        exc: 's3cr3t'
    }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
